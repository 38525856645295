import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import Loader from 'components/Loader/Loader';

export const Field = styled(Form.Control)`
  &.form-control {
    height: 50px;
    background-color: var(--secondary-background-color);
    border: none;

    &:focus { background-color: var(--secondary-background-color); }
    &::placeholder { color: var(--secondary-label-color); }
    :-ms-input-placeholder { color: var(--secondary-label-color); } /* Internet Explorer 10-11 */
    ::-ms-input-placeholder { color: var(--secondary-label-color); } /* Microsoft Edge */
  }
`;

export const FormSelect = styled.div`
  select.form-control {
    height: 50px;
    background-color: var(--secondary-background-color);
    border: none;

    &:focus {
      background-color: var(--secondary-background-color);
    }
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;

  & input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 2px;
    bottom: 2px;
    background-color: var(--background-color);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--blue);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--blue);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 23px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
`;

const ButtonStyle = styled.button`
  border: 1px var(--border-color) solid;
  padding: 5px 10px;
  box-shadow: var(--devo-shadow);
  background-color: var(--background-color);
  border-radius: var(--devo-border-radius);

  .loader {
    margin: 5px;
  }

  &.green {
    border: none;
    background-color: var(--devo-green);
    color: white;
  }

  &.red {
    border: none;
    background-color: var(--devo-red);
    color: white;
  }
`;

export const Button = ({ children, loading = false, onClick = null, className = "" }) => (
  <ButtonStyle type={"button"} onClick={onClick} className={className}>
    {loading ? <Loader /> : children}
  </ButtonStyle>
)

export const Switch = ({ isOn, onClick }) => {
  return (
    <SwitchLabel className="switch">
      <input type="checkbox" checked={isOn} onChange={onClick} />
      <span className="slider round" />
    </SwitchLabel>
  );
};

export const DropdownButton = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  .dropdown .btn {
    padding: 5px 10px;
    color: var(--primary-label-color);
    background-color: var(--background-color);
    border: var(--devo-border);
    border-radius: var(--devo-border-radius);
    box-shadow: var(--devo-shadow);
  }

  .dropdown .btn:focus, .btn:active {
    background-color: var(--background-color) !important;
    color: var(--primary-label-color) !important;
    border: var(--devo-border);
  }
`;